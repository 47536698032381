.questions {
  margin-top: 5.35%;
  align-items: flex-end;
  padding-left: var(--container-offset);

  @media (max-width: $viewport-l) {
    overflow-x: hidden;
  }

  @media (max-width: $viewport-s) {
    margin-top: 9.7%;
    padding-left: 30px;
    padding-right: 30px;
  }

  h2 {
    margin-bottom: 80px;
    font-size: Max(10px, Min(2.45vw, 35px));
    line-height: 1.29;
    text-transform: uppercase;
    color: $color-red;

    @media (max-width: $viewport-s) {
      margin-bottom: 18%;
      text-align: center;
    }
  }
}

.questions__inner {
  position: relative;
  display: flex;
  align-items: flex-end;

  @media (max-width: $viewport-s) {
    flex-direction: column;
    align-items: center;
  }
}

.questions__list {
  display: flex;
  width: 58.29%;
  margin-right: -12.4%;
  padding-bottom: 70px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: $viewport-s) {
    width: 100%;
    margin-right: 0;
    padding-bottom: 75px;
    flex-direction: column;
    align-items: center;
  }

  li {
    position: relative;
    width: 48.14%;
    padding: 88px 22px 36px;
    box-shadow: -8px 8px 12px rgba(0, 0, 0, .25);
    border-radius: 20px;
    font-size: Max(10px, Min(1.75vw, 25px));
    line-height: 1.28;
    background-color: $color-white;
    color: #1B1A1A;

    @media (max-width: $viewport-s) {
      font-size: Max(12px, Min(1.8vw, 14px));
      width: 75%;
      padding: 40px 22px 19px;
    }

    &:first-child {
      margin-right: 3.59%;

      @media (max-width: $viewport-s) {
        margin-right: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 55px;

      @media (max-width: $viewport-s) {
        margin-bottom: 46px;
      }
    }
  }

  span {
    position: absolute;
    display: flex;
    width: 91px;
    height: 91px;
    top: 0;
    left: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $color-red;
    transform: translate(-50%, -50%);

    @media (max-width: $viewport-s) {
      width: 48px;
      height: 48px;
    }
  }
}

.svg-celsium {
  width: 37px;
  height: 29px;

  @media (max-width: $viewport-s) {
    width: 24px;
    height: 18px;
  }
}

.svg-meat {
  width: 32px;
  height: 45px;

  @media (max-width: $viewport-s) {
    width: 19px;
    height: 28px;
  }
}

.svg-temp {
  width: 24px;
  height: 43px;

  @media (max-width: $viewport-s) {
    width: 15px;
    height: 27px;
  }
}

.questions__recap {
  width: 43.02%;
  padding: 22px 39px 16px 29px;
  border-radius: 15px;
  background: $color-red url("../img/bg/pattern.jpg") 29px 50%/ cover no-repeat;
  color: $color-white;
  box-shadow: -8px 8px 12px rgba(0, 0, 0, .25);

  @media (max-width: $viewport-s) {
    width: 100%;
    max-width: 531px;
    background-position: 15px 50%;
    padding: 16px 29px 16px 15px;
  }

  strong {
    display: block;
    margin-left: 39px;
    font-weight: normal;
    font-size: Max(10px, Min(2.45vw, 35px));
    line-height: 1.26;
    text-transform: uppercase;

    @media (max-width: $viewport-s) {
      margin-left: 10px;
      margin-bottom: 8px;
    }
  }

  p {
    margin-left: 39px;
    font-size: Max(8px, Min(1.4vw, 20px));
    line-height: 1.3;

    @media (max-width: $viewport-s) {
      margin-left: 10px;
      line-height: 1.4;
      font-size: Max(8px, Min(1.8vw, 10px));
    }
  }
}

.questions__wrap-img {
  position: absolute;
  width: 69.38%;
  max-height: 602px;
  top: -80px;
  right: -205px;
  z-index: -1;
  overflow: hidden;

  @media (max-width: 472px) {
    right: -270px;
  }

  @media (max-width: $viewport-s) {
    width: 154%;
    max-width: 600px;
    top: initial;
    right: -270px;
    bottom: 9px;
  }

  @media (max-width: 472px) {
    right: -65.76%;
  }
}
