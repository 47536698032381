:root {
  --container-offset: 150px;

  @media (max-width: $viewport-mw) {
    --container-offset: 50px;
  }

  @media (max-width: $viewport-m) {
    --container-offset: 30px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.container {
  padding: 0 var(--container-offset);
}

.btn {
  position: relative;
  min-width: Max(123px, Min(30%, 241px));
  padding: 17px 30px;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  transition-property: color, background-color;
  transition-duration: $delay-default;

  &--white {
    color: $color-red;
    background-color: $color-white;

    &::after {
      border: 4px solid $btn-white-bd-color;
    }

    &:hover,
    &:focus {
      color: $color-white;
      background-color: $color-red;

      &::after {
        border-color: $color-white;
      }
    }
  }

  &--red {
    color: $color-white;
    background-color: $color-red;

    &::after {
      border: 4px solid $color-white;
    }

    &:hover,
    &:focus {
      color: $color-red;
      background-color: $color-white;

      &::after {
        border-color: $color-red;
      }
    }
  }

  @media (max-width: $viewport-m) {
    font-size: Max(15px, Min(5vw, 25px));
    padding: 15px 30px;
  }

  @media (max-width: $viewport-s) {
    font-size: Max(8px, Min(1.5vw, 25px));
    padding: 10px 30px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15px;
    left: 15px;
    z-index: -1;
    transition: border-color $delay-default;

    @media (max-width: $viewport-m) {
      top: 5px;
      left: 5px;
      border-width: 2px;
    }
  }
}

.object-fit {
  height: 100%;
  object-fit: cover;
  background-color: $bg-img;
}
