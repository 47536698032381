.order {
  max-width: 1248px;
  position: relative;
  padding: 26px 8% 36px;
  margin-top: 103px;
  margin-right: 71px;
  border-radius: 0 30px 30px 0;
  box-shadow: -8px 8px 12px rgba(0, 0, 0, .25);
  background: url("../img/bg/pattern.jpg");
  color: $color-white;

  @media (min-width: 1441px) {
    max-width: initial;
    margin-right: calc(50vw - 570px);
  }

  @media (max-width: $viewport-s) {
    margin-right: 29px;
    margin-top: 11.6%;
    padding: 26px 12% 36px;
    border-radius: 0 10px 10px 0;
  }

  &::after {
    content: "";
    position: absolute;
    height: calc(100% - 70px);
    width: calc(100% + 41px);
    top: 112px;
    left: 0;
    border-radius: 0 30px 30px 0;
    box-shadow: -8px 8px 12px rgba(0, 0, 0, .25);
    background-color: $color-red;
    z-index: -1;

    @media (max-width: $viewport-s) {
      width: calc(100% + 14px);
      height: calc(100% - 95px);
      border-radius: 0 10px 10px 0;
    }
  }
}

.order__wrap {
  max-width: 946px;
  margin-left: auto;
}

.order__header {
  h2 {
    margin-bottom: 19px;
    font-size: Max(15px, Min(2.7vw, 35px));
    line-height: 1.29;
    text-align: center;

    @media (max-width: $viewport-s) {
      font-size: Max(10px, Min(2.7vw, 35px));
    }
  }

  p {
    margin: 0 auto 26px;
    max-width: 609px;
    font-size: Max(15px, Min(1.8vw, 25px));
    line-height: 1.28;
    text-align: center;

    @media (max-width: $viewport-s) {
      font-size: Max(10px, Min(1.8vw, 25px));
    }
  }
}

.order__form {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $viewport-s) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.order__label {
  display: block;
  height: 62px;
  flex-grow: 1;

  @media (max-width: $viewport-s) {
    height: 40px;
  }

  &:not(:last-of-type) {
    margin-right: 7%;

    @media (max-width: $viewport-s) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0 23px;
    border: 0;
    color: #000;

    &::placeholder {
      color: #858585;
    }
  }
}

.order__btn-wrap {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
  contain: layout; // для создания контекста наложения

  button {

  }
}
