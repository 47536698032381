.promo {
  position: relative;
  margin: auto;
  padding-bottom: 35px;
  background: url("../img/bg/banner.jpg") 50% /cover no-repeat;
  border-radius: 0 0 150px 0;
  z-index: 0;
  color: $color-white;

  @media (max-width: $viewport-m) {
    padding-bottom: 22px;
    border-radius: 0 0 40px 0;
    background-position: 50% 80px;
  }

  @media (max-width: $viewport-s) {
    background-position: 50% 50px;
  }

  h1 {
    margin-bottom: 39px;
    font-size: Max(13px, Min(2.8vw, 40px));
    line-height: 1.3;
    text-transform: uppercase;

    @media (max-width: $viewport-s) {
      margin-bottom: 14px;
      line-height: 1.3;
    }

    span {
      display: block;
      color: $color-red;
    }
  }
}

.promo__top {
  position: relative;
  z-index: -5;
  display: grid;
  grid-template-columns: 46% 21% 33%;
  margin-top: 43px;
  padding-left: var(--container-offset);

  @media (max-width: $viewport-s) {
    display: flex;
    justify-content: space-between;
    margin-top: 39px;
  }
}

.promo__top-content {
  grid-area: 1 / 1 / 2 / 3;
  margin-top: 90px;
  z-index: 1;

  @media (max-width: $viewport-s) {
    width: 59%;
    margin-top: 0;
    padding-right: 12px;
  }

  p {
    position: relative;
    margin-bottom: 11.5%;
    padding-left: 30px;
    font-size: Max(8px, Min(2.1vw, 30px));
    line-height: 1.27;

    @media (max-width: $viewport-s) {
      margin-bottom: 14%;
      padding-left: 10px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background-color: $color-white;

      @media (max-width: $viewport-s) {
        width: 5px;
        height: 5px;
        top: 3px;
      }
    }
  }

  button {
    margin-bottom: 100px;

    @media (max-width: $viewport-m) {
      margin-bottom: 50px;
    }

    @media (max-width: $viewport-s) {
      margin-bottom: 0;
    }
  }
}

.promo__img-wrap {
  grid-area: 1 / 2 / 2 / 4;
  box-shadow: -15px 20px 25px rgba(0, 0, 0, .65);
  border-radius: 50px;
  overflow: hidden;
  align-self: start;

  @media (max-width: $viewport-l) {
    border-radius: 50px 0 0 50px;
  }

  img {
    max-height: 580px;
  }

  @media (max-width: $viewport-s) {
    box-shadow: -8px 8px 20px rgba(0, 0, 0, .65);
    border-radius: 10px 0 0 10px;
    width: 41%;
  }
}

.promo__bottom {
  position: relative;
  display: flex;
  margin-top: 39px;
  padding-top: 20px;
  padding-left: 41px;
  scroll-snap-align: start;
  background: url("../img/bg/mangal.svg") right 5% top/25.98% 100% no-repeat;
  z-index: -2;

  @media (max-width: $viewport-m) {
    margin-top: 0;
    background-position: right 2% top;
  }

  @media (max-width: $viewport-s) {
    padding-top: 33px;
    padding-left: 0;
  }
}

.promo__img-pack {
  position: relative;
  width: 33%;
  max-width: 484px;
  margin-right: 18px;
  flex-shrink: 0;

  @media (max-width: $viewport-s) {
    width: 40%;
    min-height: 90px;
    margin-right: 11px;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -43%);
    background-color: transparent;
    z-index: -2;

    @media (max-width: $viewport-s) {
      width: 70%;
      left: 50%;
      transform: translate(-50%, -39%);
    }

    @media (max-width: 580px) {
      width: calc(128px + (100% - 128px) / 6);
    }
  }
}

.promo__bottom-content {
  position: relative;
  max-width: 763px;
  padding-right: 50px;
  z-index: -2;

  h2 {
    margin-bottom: 8px;
    font-size: Min(3.9vw, 55px);
    line-height: 1.3;
    text-transform: uppercase;
    color: $color-red;

    @media (max-width: $viewport-s) {
      font-size: Max(10px, Min(3vw, 55px));
      margin-left: -8px;
      margin-bottom: 12px;
    }
  }

  p {
    font-size: Max(8px, Min(2.45vw, 35px));
    line-height: 1.25;
    margin-left: 15%;

    @media (max-width: $viewport-m) {
      margin-left: 11%;
    }

    @media (max-width: $viewport-s) {
      margin-left: 0;
    }
  }
}
