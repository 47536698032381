@font-face {
  font-family: "Lora";
  src: url("../fonts/lora-regular.woff2") format("woff2"), url("../fonts/lora-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Lora";
  src: url("../fonts/lora-bold.woff2") format("woff2"), url("../fonts/lora-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
fieldset,
ul,
dd,
dl {
  margin: 0; }

ul,
legend,
fieldset {
  padding: 0; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.36;
  font-family: "Lora", "Arial", sans-serif;
  color: #000;
  overflow-x: hidden; }

*:focus:not(:focus-visible) {
  outline: none; }

a {
  display: inline-block;
  color: inherit;
  text-decoration: none; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit; }

img,
svg,
video {
  display: block;
  width: 100%;
  height: auto; }

ul {
  list-style: none; }

textarea {
  resize: none; }

input,
textarea {
  display: block;
  font-family: inherit;
  border-radius: 0;
  font-size: 16px;
  margin: 0;
  color: inherit;
  -webkit-appearance: none; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    opacity: 1; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1; }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    opacity: 1; }
  input::placeholder,
  textarea::placeholder {
    opacity: 1; }
  input:invalid,
  textarea:invalid {
    -webkit-box-shadow: none;
            box-shadow: none; }

select {
  border-radius: 0; }

input::-ms-clear, input::-ms-reveal {
  display: none; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
          appearance: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
          appearance: none; }

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield; }

select,
textarea,
input:matches([type="email"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"]) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-family: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

:root {
  --container-offset: 150px; }
  @media (max-width: 1280px) {
    :root {
      --container-offset: 50px; } }
  @media (max-width: 1024px) {
    :root {
      --container-offset: 30px; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.wrapper {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto; }

.container {
  padding: 0 var(--container-offset); }

.btn {
  position: relative;
  min-width: Max(123px, Min(30%, 241px));
  padding: 17px 30px;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }
  .btn--white {
    color: #FF1F00;
    background-color: #FFF; }
    .btn--white::after {
      border: 4px solid #7C1001; }
    .btn--white:hover, .btn--white:focus {
      color: #FFF;
      background-color: #FF1F00; }
      .btn--white:hover::after, .btn--white:focus::after {
        border-color: #FFF; }
  .btn--red {
    color: #FFF;
    background-color: #FF1F00; }
    .btn--red::after {
      border: 4px solid #FFF; }
    .btn--red:hover, .btn--red:focus {
      color: #FF1F00;
      background-color: #FFF; }
      .btn--red:hover::after, .btn--red:focus::after {
        border-color: #FF1F00; }
  @media (max-width: 1024px) {
    .btn {
      font-size: Max(15px, Min(5vw, 25px));
      padding: 15px 30px; } }
  @media (max-width: 768px) {
    .btn {
      font-size: Max(8px, Min(1.5vw, 25px));
      padding: 10px 30px; } }
  .btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15px;
    left: 15px;
    z-index: -1;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; }
    @media (max-width: 1024px) {
      .btn::after {
        top: 5px;
        left: 5px;
        border-width: 2px; } }

.object-fit {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #827E7E; }

.header__wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 1024px) {
    .header__wrap {
      background-color: #FF1F00; } }

.header__logo-wrap {
  position: relative; }

.header__logo {
  position: relative;
  width: 162px;
  height: 155px;
  padding: 0 15px 15px;
  background-color: #D81A00;
  background-clip: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden; }
  @media (max-width: 1024px) {
    .header__logo {
      background-color: initial;
      width: auto;
      height: 80px;
      padding: 0; } }
  @media (max-width: 768px) {
    .header__logo {
      height: 50px; } }
  .header__logo img {
    width: 47px;
    max-height: 89px; }
    @media (max-width: 1024px) {
      .header__logo img {
        width: auto;
        height: 50px; } }
    @media (max-width: 768px) {
      .header__logo img {
        height: 35px; } }
  .header__logo::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 30px;
    width: 132px;
    height: 160px;
    border: 5px solid #FFF;
    z-index: -1; }
    @media (max-width: 1024px) {
      .header__logo::before {
        content: none; } }

.nav {
  color: #FFF; }

.nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .nav__list {
      position: absolute;
      width: 100%;
      max-height: 0;
      top: 100%;
      left: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      background-color: #FF1F00;
      opacity: .5;
      overflow: hidden;
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      -webkit-transition-property: max-height, opacity, visibility, -webkit-transform;
      transition-property: max-height, opacity, visibility, -webkit-transform;
      transition-property: transform, max-height, opacity, visibility;
      transition-property: transform, max-height, opacity, visibility, -webkit-transform;
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
      z-index: -1; } }
  .nav__list li:not(:last-child) {
    margin-right: 43px; }
    @media (max-width: 1024px) {
      .nav__list li:not(:last-child) {
        margin-right: 0; } }
  .nav__list a {
    display: block;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    text-align: center; }
    .nav__list a:hover, .nav__list a:focus {
      color: #FF1F00; }
    @media (max-width: 1024px) {
      .nav__list a {
        padding: 15px 0; }
        .nav__list a:hover, .nav__list a:focus {
          color: inherit;
          background-color: #D81A00; } }

.nav__toggle {
  position: relative;
  display: none;
  padding: 0;
  width: 22px;
  height: 20px;
  contain: strict; }
  @media (max-width: 1024px) {
    .nav__toggle {
      display: block; } }

.nav__burger {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -11px;
  width: 22px;
  height: 3px;
  border-radius: 5px;
  background-color: #FFF; }
  .nav__burger::before, .nav__burger::after {
    content: "";
    position: absolute;
    top: -7px;
    right: 0;
    width: 22px;
    height: 100%;
    border-radius: 5px;
    background-color: #FFF;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .nav__burger::after {
    top: 7px; }

.nav--menu-active .nav__burger {
  width: 0; }

.nav--menu-active .nav__burger::before {
  -webkit-transform: translateY(7px) rotate(-45deg);
          transform: translateY(7px) rotate(-45deg); }

.nav--menu-active .nav__burger::after {
  -webkit-transform: translateY(-7px) rotate(45deg);
          transform: translateY(-7px) rotate(45deg); }

.nav--menu-active .nav__list {
  max-height: 500px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }

.promo {
  position: relative;
  margin: auto;
  padding-bottom: 35px;
  background: url("../img/bg/banner.jpg") 50%/cover no-repeat;
  border-radius: 0 0 150px 0;
  z-index: 0;
  color: #FFF; }
  @media (max-width: 1024px) {
    .promo {
      padding-bottom: 22px;
      border-radius: 0 0 40px 0;
      background-position: 50% 80px; } }
  @media (max-width: 768px) {
    .promo {
      background-position: 50% 50px; } }
  .promo h1 {
    margin-bottom: 39px;
    font-size: Max(13px, Min(2.8vw, 40px));
    line-height: 1.3;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .promo h1 {
        margin-bottom: 14px;
        line-height: 1.3; } }
    .promo h1 span {
      display: block;
      color: #FF1F00; }

.promo__top {
  position: relative;
  z-index: -5;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 46% 21% 33%;
  grid-template-columns: 46% 21% 33%;
  margin-top: 43px;
  padding-left: var(--container-offset); }
  @media (max-width: 768px) {
    .promo__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-top: 39px; } }

.promo__top-content {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: 1 / 1 / 2 / 3;
  margin-top: 90px;
  z-index: 1; }
  @media (max-width: 768px) {
    .promo__top-content {
      width: 59%;
      margin-top: 0;
      padding-right: 12px; } }
  .promo__top-content p {
    position: relative;
    margin-bottom: 11.5%;
    padding-left: 30px;
    font-size: Max(8px, Min(2.1vw, 30px));
    line-height: 1.27; }
    @media (max-width: 768px) {
      .promo__top-content p {
        margin-bottom: 14%;
        padding-left: 10px; } }
    .promo__top-content p::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background-color: #FFF; }
      @media (max-width: 768px) {
        .promo__top-content p::before {
          width: 5px;
          height: 5px;
          top: 3px; } }
  .promo__top-content button {
    margin-bottom: 100px; }
    @media (max-width: 1024px) {
      .promo__top-content button {
        margin-bottom: 50px; } }
    @media (max-width: 768px) {
      .promo__top-content button {
        margin-bottom: 0; } }

.promo__img-wrap {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: 1 / 2 / 2 / 4;
  -webkit-box-shadow: -15px 20px 25px rgba(0, 0, 0, 0.65);
          box-shadow: -15px 20px 25px rgba(0, 0, 0, 0.65);
  border-radius: 50px;
  overflow: hidden;
  -ms-grid-row-align: start;
      align-self: start; }
  @media (max-width: 1440px) {
    .promo__img-wrap {
      border-radius: 50px 0 0 50px; } }
  .promo__img-wrap img {
    max-height: 580px; }
  @media (max-width: 768px) {
    .promo__img-wrap {
      -webkit-box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.65);
              box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.65);
      border-radius: 10px 0 0 10px;
      width: 41%; } }

.promo__bottom {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 39px;
  padding-top: 20px;
  padding-left: 41px;
  scroll-snap-align: start;
  background: url("../img/bg/mangal.svg") right 5% top/25.98% 100% no-repeat;
  z-index: -2; }
  @media (max-width: 1024px) {
    .promo__bottom {
      margin-top: 0;
      background-position: right 2% top; } }
  @media (max-width: 768px) {
    .promo__bottom {
      padding-top: 33px;
      padding-left: 0; } }

.promo__img-pack {
  position: relative;
  width: 33%;
  max-width: 484px;
  margin-right: 18px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  @media (max-width: 768px) {
    .promo__img-pack {
      width: 40%;
      min-height: 90px;
      margin-right: 11px; } }
  .promo__img-pack img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -43%);
            transform: translate(-50%, -43%);
    background-color: transparent;
    z-index: -2; }
    @media (max-width: 768px) {
      .promo__img-pack img {
        width: 70%;
        left: 50%;
        -webkit-transform: translate(-50%, -39%);
                transform: translate(-50%, -39%); } }
    @media (max-width: 580px) {
      .promo__img-pack img {
        width: calc(128px + (100% - 128px) / 6); } }

.promo__bottom-content {
  position: relative;
  max-width: 763px;
  padding-right: 50px;
  z-index: -2; }
  .promo__bottom-content h2 {
    margin-bottom: 8px;
    font-size: Min(3.9vw, 55px);
    line-height: 1.3;
    text-transform: uppercase;
    color: #FF1F00; }
    @media (max-width: 768px) {
      .promo__bottom-content h2 {
        font-size: Max(10px, Min(3vw, 55px));
        margin-left: -8px;
        margin-bottom: 12px; } }
  .promo__bottom-content p {
    font-size: Max(8px, Min(2.45vw, 35px));
    line-height: 1.25;
    margin-left: 15%; }
    @media (max-width: 1024px) {
      .promo__bottom-content p {
        margin-left: 11%; } }
    @media (max-width: 768px) {
      .promo__bottom-content p {
        margin-left: 0; } }

.desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 6% auto 0;
  padding-top: 8.96%;
  padding-bottom: 6.94%;
  background-image: url("../img/bg/main-decor-top.png"), url("../img/bg/main-decor-bottm.png");
  background-position: 50% 0, 50% 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: 39.93% auto, 39.93% auto; }
  @media (max-width: 1024px) {
    .desc {
      margin-top: 9%;
      padding-left: 39px;
      padding-right: 39px; } }
  @media (max-width: 768px) {
    .desc {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-bottom: 9%; } }

.desc__content {
  width: 58.42%;
  margin-right: 22px;
  font-size: Max(10px, Min(1.8vw, 25px)); }
  @media (max-width: 768px) {
    .desc__content {
      width: 100%;
      margin-right: 0;
      margin-top: 17px; } }
  .desc__content p {
    line-height: 1.4; }

.desc__img-wrap {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  @media (max-width: 768px) {
    .desc__img-wrap {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1;
      padding: 0 15px; } }
  .desc__img-wrap img {
    -webkit-box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.25);
            box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: #827E7E; }
    @media (max-width: 768px) {
      .desc__img-wrap img {
        border-radius: 5px; } }

.desc__top-img {
  width: 91.6%; }
  @media (max-width: 768px) {
    .desc__top-img {
      width: 60%; } }

.desc__bottom-img {
  width: 67.92%;
  margin-top: -70px;
  margin-left: auto; }
  @media (max-width: 768px) {
    .desc__bottom-img {
      width: 60%;
      margin-top: -59px; } }

.questions {
  margin-top: 5.35%;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-left: var(--container-offset); }
  @media (max-width: 1440px) {
    .questions {
      overflow-x: hidden; } }
  @media (max-width: 768px) {
    .questions {
      margin-top: 9.7%;
      padding-left: 30px;
      padding-right: 30px; } }
  .questions h2 {
    margin-bottom: 80px;
    font-size: Max(10px, Min(2.45vw, 35px));
    line-height: 1.29;
    text-transform: uppercase;
    color: #FF1F00; }
    @media (max-width: 768px) {
      .questions h2 {
        margin-bottom: 18%;
        text-align: center; } }

.questions__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }
  @media (max-width: 768px) {
    .questions__inner {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }

.questions__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 58.29%;
  margin-right: -12.4%;
  padding-bottom: 70px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (max-width: 768px) {
    .questions__list {
      width: 100%;
      margin-right: 0;
      padding-bottom: 75px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  .questions__list li {
    position: relative;
    width: 48.14%;
    padding: 88px 22px 36px;
    -webkit-box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
            box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-size: Max(10px, Min(1.75vw, 25px));
    line-height: 1.28;
    background-color: #FFF;
    color: #1B1A1A; }
    @media (max-width: 768px) {
      .questions__list li {
        font-size: Max(12px, Min(1.8vw, 14px));
        width: 75%;
        padding: 40px 22px 19px; } }
    .questions__list li:first-child {
      margin-right: 3.59%; }
      @media (max-width: 768px) {
        .questions__list li:first-child {
          margin-right: 0; } }
    .questions__list li:not(:last-child) {
      margin-bottom: 55px; }
      @media (max-width: 768px) {
        .questions__list li:not(:last-child) {
          margin-bottom: 46px; } }
  .questions__list span {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 91px;
    height: 91px;
    top: 0;
    left: 50%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 50%;
    background-color: #FF1F00;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (max-width: 768px) {
      .questions__list span {
        width: 48px;
        height: 48px; } }

.svg-celsium {
  width: 37px;
  height: 29px; }
  @media (max-width: 768px) {
    .svg-celsium {
      width: 24px;
      height: 18px; } }

.svg-meat {
  width: 32px;
  height: 45px; }
  @media (max-width: 768px) {
    .svg-meat {
      width: 19px;
      height: 28px; } }

.svg-temp {
  width: 24px;
  height: 43px; }
  @media (max-width: 768px) {
    .svg-temp {
      width: 15px;
      height: 27px; } }

.questions__recap {
  width: 43.02%;
  padding: 22px 39px 16px 29px;
  border-radius: 15px;
  background: #FF1F00 url("../img/bg/pattern.jpg") 29px 50%/cover no-repeat;
  color: #FFF;
  -webkit-box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
          box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25); }
  @media (max-width: 768px) {
    .questions__recap {
      width: 100%;
      max-width: 531px;
      background-position: 15px 50%;
      padding: 16px 29px 16px 15px; } }
  .questions__recap strong {
    display: block;
    margin-left: 39px;
    font-weight: normal;
    font-size: Max(10px, Min(2.45vw, 35px));
    line-height: 1.26;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .questions__recap strong {
        margin-left: 10px;
        margin-bottom: 8px; } }
  .questions__recap p {
    margin-left: 39px;
    font-size: Max(8px, Min(1.4vw, 20px));
    line-height: 1.3; }
    @media (max-width: 768px) {
      .questions__recap p {
        margin-left: 10px;
        line-height: 1.4;
        font-size: Max(8px, Min(1.8vw, 10px)); } }

.questions__wrap-img {
  position: absolute;
  width: 69.38%;
  max-height: 602px;
  top: -80px;
  right: -205px;
  z-index: -1;
  overflow: hidden; }
  @media (max-width: 472px) {
    .questions__wrap-img {
      right: -270px; } }
  @media (max-width: 768px) {
    .questions__wrap-img {
      width: 154%;
      max-width: 600px;
      top: initial;
      right: -270px;
      bottom: 9px; } }
  @media (max-width: 472px) {
    .questions__wrap-img {
      right: -65.76%; } }

.info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 162px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 768px) {
    .info {
      margin-top: 12%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }

.info__wrap-img {
  width: 51.36%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-top: -95px;
  margin-right: -25.5%;
  max-height: 489px;
  border-radius: 25px;
  -webkit-box-shadow: -10px 8px 35px rgba(0, 0, 0, 0.25);
          box-shadow: -10px 8px 35px rgba(0, 0, 0, 0.25);
  overflow: hidden; }
  @media (max-width: 768px) {
    .info__wrap-img {
      width: 76.92%;
      margin: 0 0 -20px 0;
      border-radius: 10px; } }
  .info__wrap-img img {
    background-color: #827E7E; }

.info__content {
  width: 74.12%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 11.4% 4.82% 11.4% 32.08%;
  -webkit-box-shadow: 10px 8px 35px rgba(0, 0, 0, 0.2);
          box-shadow: 10px 8px 35px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  background-image: url("../img/bg/main-decor-top.png"), url("../img/bg/main-decor-bottm.png");
  background-position: 80% 13%, 80% 87%;
  background-repeat: no-repeat, no-repeat;
  background-size: 43.9% auto, 43.9% auto; }
  @media (max-width: 768px) {
    .info__content {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: calc(50px + 7.7%) 7.69% calc(28px + 7.7%);
      border-radius: 10px;
      background-size: 55.77% auto, 55.77% auto;
      background-position: center top 37px, center bottom 13px; } }
  .info__content p {
    font-size: Max(10px, Min(1.6vw, 25px));
    line-height: 1.26; }
    .info__content p + p {
      margin-top: 15px; }

.order {
  max-width: 1248px;
  position: relative;
  padding: 26px 8% 36px;
  margin-top: 103px;
  margin-right: 71px;
  border-radius: 0 30px 30px 0;
  -webkit-box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
          box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
  background: url("../img/bg/pattern.jpg");
  color: #FFF; }
  @media (min-width: 1441px) {
    .order {
      max-width: initial;
      margin-right: calc(50vw - 570px); } }
  @media (max-width: 768px) {
    .order {
      margin-right: 29px;
      margin-top: 11.6%;
      padding: 26px 12% 36px;
      border-radius: 0 10px 10px 0; } }
  .order::after {
    content: "";
    position: absolute;
    height: calc(100% - 70px);
    width: calc(100% + 41px);
    top: 112px;
    left: 0;
    border-radius: 0 30px 30px 0;
    -webkit-box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
            box-shadow: -8px 8px 12px rgba(0, 0, 0, 0.25);
    background-color: #FF1F00;
    z-index: -1; }
    @media (max-width: 768px) {
      .order::after {
        width: calc(100% + 14px);
        height: calc(100% - 95px);
        border-radius: 0 10px 10px 0; } }

.order__wrap {
  max-width: 946px;
  margin-left: auto; }

.order__header h2 {
  margin-bottom: 19px;
  font-size: Max(15px, Min(2.7vw, 35px));
  line-height: 1.29;
  text-align: center; }
  @media (max-width: 768px) {
    .order__header h2 {
      font-size: Max(10px, Min(2.7vw, 35px)); } }

.order__header p {
  margin: 0 auto 26px;
  max-width: 609px;
  font-size: Max(15px, Min(1.8vw, 25px));
  line-height: 1.28;
  text-align: center; }
  @media (max-width: 768px) {
    .order__header p {
      font-size: Max(10px, Min(1.8vw, 25px)); } }

.order__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (max-width: 768px) {
    .order__form {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.order__label {
  display: block;
  height: 62px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  @media (max-width: 768px) {
    .order__label {
      height: 40px; } }
  .order__label:not(:last-of-type) {
    margin-right: 7%; }
    @media (max-width: 768px) {
      .order__label:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 15px; } }
  .order__label input {
    width: 100%;
    height: 100%;
    padding: 0 23px;
    border: 0;
    color: #000; }
    .order__label input::-webkit-input-placeholder {
      color: #858585; }
    .order__label input::-moz-placeholder {
      color: #858585; }
    .order__label input::-ms-input-placeholder {
      color: #858585; }
    .order__label input::placeholder {
      color: #858585; }

.order__btn-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  contain: layout; }

.certificate {
  max-width: 1010px;
  margin: 108px auto 0;
  padding: 0 50px; }
  @media (max-width: 768px) {
    .certificate {
      margin-top: 14%; } }
  .certificate b {
    display: block;
    margin-bottom: 14px;
    font-size: Max(10px, Min(2.1vw, 30px));
    font-weight: bold;
    line-height: 1.27;
    text-transform: uppercase;
    text-align: center;
    color: #EF1D00; }
  .certificate p {
    margin: -18px auto 0;
    max-width: 964px;
    font-weight: bold;
    font-size: Max(10px, Min(2.1vw, 30px));
    line-height: 1.27;
    text-align: center;
    color: #4D4D4D; }
    @media (max-width: 768px) {
      .certificate p {
        margin-top: 0; } }

.certificate__img-wrap {
  max-width: 575px;
  margin: auto;
  padding-top: 32px;
  background: url("../img/bg/main-decor-bottm.png") 0 0/100% auto no-repeat; }
  @media (max-width: 768px) {
    .certificate__img-wrap {
      padding-top: 8px;
      background-size: 60% auto;
      background-position: 50% 0; } }

.footer {
  --img-coals-width: 42%;
  margin-top: 60px;
  contain: layout;
  color: #FFF; }
  @media (max-width: 768px) {
    .footer {
      margin-top: 32%; } }

.footer__top {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: var(--img-coals-width) 1fr;
  grid-template-columns: var(--img-coals-width) 1fr; }
  @media (max-width: 768px) {
    .footer__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 0 30px 25px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-radius: 40px 0 0 0;
      background: #827E7E url("../img/bg/pattern.jpg"); } }

.footer__img-wrap {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 1 / 2;
  max-height: 681px;
  padding-left: 25px;
  z-index: 1; }
  @media (max-width: 1440px) {
    .footer__img-wrap {
      padding-left: 4.13%; } }
  .footer__img-wrap img {
    max-width: 580px;
    margin-left: auto; }
  @media (max-width: 768px) {
    .footer__img-wrap {
      max-width: 400px;
      width: 92%;
      padding-left: 0;
      padding-right: 21px;
      margin: -87px auto 8px; } }
  @media (max-width: 496px) {
    .footer__img-wrap {
      margin: -20% auto 8px; } }

.footer__top-cont-wrap {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: 1 / 1 / 1 / 3;
  padding-left: var(--img-coals-width);
  padding-right: 72px;
  margin-top: 249px;
  padding-bottom: 40px;
  background: #827E7E url("../img/bg/pattern.jpg");
  border-radius: 150px 0 0 0;
  z-index: 0; }
  @media (max-width: 1024px) {
    .footer__top-cont-wrap {
      border-radius: 40px 0 0 0; } }
  @media (max-width: 1440px) {
    .footer__top-cont-wrap {
      margin-top: 17.3%;
      padding-right: 5%; } }
  @media (max-width: 768px) {
    .footer__top-cont-wrap {
      padding: 0;
      margin-top: 0;
      background: none; } }

.footer__top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 42px;
  padding-top: 91px;
  max-width: 664px; }
  @media (max-width: 1440px) {
    .footer__top-content {
      padding-top: 11.92%;
      padding-left: 5.44%; } }
  @media (max-width: 768px) {
    .footer__top-content {
      padding-top: 0;
      padding-left: 0; } }
  .footer__top-content b {
    font-weight: bold;
    font-size: Max(10px, Min(2.1vw, 30px));
    line-height: 1.27;
    color: #FBFBFB; }
    @media (max-width: 768px) {
      .footer__top-content b:last-of-type {
        font-size: 15px;
        margin-bottom: 16px; } }
  .footer__top-content p {
    margin: 22px 0;
    font-size: Max(10px, Min(1.8vw, 25px));
    line-height: 1.28;
    color: #FBFBFB; }
    @media (max-width: 768px) {
      .footer__top-content p {
        margin: 8px 0 20px 0; } }
  @media (max-width: 768px) {
    .footer__top-content p,
    .footer__top-content b {
      text-align: center; } }
  .footer__top-content button {
    margin: -27px 5% 0 auto; }
    .footer__top-content button span:last-child {
      display: none; }
    @media (max-width: 768px) {
      .footer__top-content button {
        margin: auto; }
        .footer__top-content button span:first-child {
          display: none; }
        .footer__top-content button span:last-child {
          display: inline; } }

.footer__main {
  scroll-snap-align: start;
  background-color: #FF1F00; }

.footer__main-wrap {
  max-width: 1440px;
  margin: auto;
  padding-top: 24px;
  padding-bottom: 85px; }
  @media (max-width: 768px) {
    .footer__main-wrap {
      padding-bottom: 27px; } }
  .footer__main-wrap h2 {
    margin-bottom: 25px;
    font-size: Max(10px, Min(2.6vw, 33px));
    line-height: 1.27;
    text-align: center; }

.footer__delivery {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (Min(30%, 269px))[3];
  grid-template-columns: repeat(3, Min(30%, 269px));
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 50px; }
  @media (max-width: 768px) {
    .footer__delivery {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -webkit-box-pack: initial;
          -ms-flex-pack: initial;
              justify-content: initial;
      padding: 0 40px;
      gap: 20px; } }
  .footer__delivery li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .footer__delivery li strong {
      margin-bottom: 26px;
      font-size: Max(10px, Min(2.3vw, 28px));
      line-height: 1.29;
      text-align: center; }
      @media (max-width: 768px) {
        .footer__delivery li strong {
          margin-bottom: 5px; } }
    .footer__delivery li p {
      font-size: Max(10px, Min(1.4vw, 20px));
      line-height: 1.3;
      text-align: center; }

.icon-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 122px;
  height: 122px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #D81A00;
  border-radius: 50%; }
  @media (max-width: 768px) {
    .icon-wrap {
      width: 46px;
      height: 46px; } }

.svg-car {
  width: 71px;
  height: 48px; }
  @media (max-width: 768px) {
    .svg-car {
      width: 27px;
      height: 19px; } }

.svg-cash {
  width: 49px;
  height: 43px; }
  @media (max-width: 768px) {
    .svg-cash {
      width: 19px;
      height: 17px; } }

.svg-card {
  width: 58px;
  height: 43px; }
  @media (max-width: 768px) {
    .svg-card {
      width: 22px;
      height: 17px; } }

.footer__bottom {
  padding: 25px 0;
  background: #1B1A1A; }
  @media (max-width: 768px) {
    .footer__bottom {
      padding: 44px 0; } }

.footer__contact {
  max-width: 1440px;
  padding: 0 6.94%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (auto)[3];
  grid-template-columns: repeat(3, auto);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 33px; }
  @media (max-width: 768px) {
    .footer__contact {
      -ms-grid-columns: auto;
      grid-template-columns: auto;
      -webkit-box-pack: initial;
          -ms-flex-pack: initial;
              justify-content: initial;
      padding: 0 30px; } }
  .footer__contact a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: Max(10px, Min(1.8vw, 25px));
    line-height: 1.16; }
    @media (max-width: 768px) {
      .footer__contact a {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .footer__contact svg {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 21px;
    max-height: 44px; }
    @media (max-width: 768px) {
      .footer__contact svg {
        margin-right: 0;
        margin-bottom: 20px;
        width: 33px; } }

.svg-phone {
  width: 37px; }

.svg-mail {
  width: 44px; }

.svg-mark {
  width: 34px; }

.footer__logo {
  display: none; }
  @media (max-width: 768px) {
    .footer__logo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 67px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #FF1F00; }
      .footer__logo a {
        width: 25px;
        height: 47px;
        overflow: hidden; } }
