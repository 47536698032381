.desc {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 6% auto 0;
  padding-top: 8.96%;
  padding-bottom: 6.94%;
  background-image: url("../img/bg/main-decor-top.png"), url("../img/bg/main-decor-bottm.png");
  background-position: 50% 0, 50% 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: 39.93% auto, 39.93% auto;

  @media (max-width: $viewport-m) {
    margin-top: 9%;
    padding-left: 39px;
    padding-right: 39px;
  }

  @media (max-width: $viewport-s) {
    flex-direction: column;
    padding-bottom: 9%;
  }
}

.desc__content {
  width: 58.42%;
  margin-right: 22px;
  font-size: Max(10px, Min(1.8vw, 25px));

  @media (max-width: $viewport-s) {
    width: 100%;
    margin-right: 0;
    margin-top: 17px;
  }

  p {
    line-height: 1.4;
  }
}

.desc__img-wrap {
  flex-grow: 1;

  @media (max-width: $viewport-s) {
    order: -1;
    padding: 0 15px;
  }

  img {
    box-shadow: -8px 8px 20px rgba(0, 0, 0, .25);
    border-radius: 20px;
    background-color: $bg-img;

    @media (max-width: $viewport-s) {
      border-radius: 5px;
    }
  }
}

.desc__top-img {
  width: 91.6%;

  @media (max-width: $viewport-s) {
    width: 60%;
  }
}

.desc__bottom-img {
  width: 67.92%;
  margin-top: -70px;
  margin-left: auto;

  @media (max-width: $viewport-s) {
    width: 60%;
    margin-top: -59px;
  }
}
