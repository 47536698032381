.footer {
  --img-coals-width: 42%;
  margin-top: 60px;
  contain: layout;
  color: $color-white;

  @media (max-width: $viewport-s) {
    margin-top: 32%;
  }
}

.footer__top {
  display: grid;
  grid-template-columns: var(--img-coals-width) 1fr;

  @media (max-width: $viewport-s) {
    display: flex;
    padding: 0 30px 25px;
    flex-direction: column;
    border-radius: 40px 0 0 0;
    background: $bg-img url("../img/bg/pattern.jpg");
  }
}

.footer__img-wrap {
  grid-area: 1 / 1 / 1 / 2;
  max-height: 681px;
  padding-left: 25px;
  z-index: 1;

  @media (max-width: $viewport-l) {
    padding-left: 4.13%;
  }

  img {
    max-width: 580px;
    margin-left: auto;
  }

  @media (max-width: $viewport-s) {
    max-width: 400px;
    width: 92%;
    padding-left: 0;
    padding-right: 21px;
    margin: -87px auto 8px;
  }

  @media (max-width: 496px) {
    margin: -20% auto 8px;
  }
}

.footer__top-cont-wrap {
  grid-area: 1 / 1 / 1 / 3;
  padding-left: var(--img-coals-width);
  padding-right: 72px;
  margin-top: 249px;
  padding-bottom: 40px;
  background: $bg-img url("../img/bg/pattern.jpg");
  border-radius: 150px 0 0 0;
  z-index: 0;

  @media (max-width: $viewport-m) {
    border-radius: 40px 0 0 0;
  }

  @media (max-width: $viewport-l) {
    margin-top: 17.3%;
    padding-right: 5%;
  }

  @media (max-width: $viewport-s) {
    padding: 0;
    margin-top: 0;
    background: none;
  }
}

.footer__top-content {
  display: flex;
  flex-direction: column;
  padding-left: 42px;
  padding-top: 91px;
  max-width: 664px;

  @media (max-width: $viewport-l) {
    padding-top: 11.92%;
    padding-left: 5.44%;
  }

  @media (max-width: $viewport-s) {
    padding-top: 0;
    padding-left: 0;
  }

  b {
    font-weight: bold;
    font-size: Max(10px, Min(2.1vw, 30px));
    line-height: 1.27;
    color: #FBFBFB;

    &:last-of-type {
      @media (max-width: $viewport-s) {
        font-size: 15px;
        margin-bottom: 16px;
      }
    }
  }

  p {
    margin: 22px 0;
    font-size: Max(10px, Min(1.8vw, 25px));
    line-height: 1.28;
    color: #FBFBFB;

    @media (max-width: $viewport-s) {
      margin: 8px 0 20px 0;
    }
  }

  p,
  b {
    @media (max-width: $viewport-s) {
      text-align: center;
    }
  }

  button {
    margin: -27px 5% 0 auto;

    span:last-child {
      display: none;
    }

    @media (max-width: $viewport-s) {
      margin: auto;

      span:first-child {
        display: none;
      }

      span:last-child {
        display: inline;
      }
    }
  }
}

// footer__main

.footer__main {
  scroll-snap-align: start;
  background-color: $color-red;
}

.footer__main-wrap {
  max-width: 1440px;
  margin: auto;
  padding-top: 24px;
  padding-bottom: 85px;

  @media (max-width: $viewport-s) {
    padding-bottom: 27px;
  }

  h2 {
    margin-bottom: 25px;
    font-size: Max(10px, Min(2.6vw, 33px));
    line-height: 1.27;
    text-align: center;
  }
}

.footer__delivery {
  display: grid;
  grid-template-columns: repeat(3, Min(30%, 269px));
  justify-content: space-between;
  gap: 50px;

  @media (max-width: $viewport-s) {
    grid-template-columns: 1fr;
    justify-content: initial;
    padding: 0 40px;
    gap: 20px;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      margin-bottom: 26px;
      font-size: Max(10px, Min(2.3vw, 28px));
      line-height: 1.29;
      text-align: center;

      @media (max-width: $viewport-s) {
        margin-bottom: 5px;
      }
    }

    p {
      font-size: Max(10px, Min(1.4vw, 20px));
      line-height: 1.3;
      text-align: center;
    }
  }
}

.icon-wrap {
  display: flex;
  width: 122px;
  height: 122px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  background-color: $bg-icon;
  border-radius: 50%;

  @media (max-width: $viewport-s) {
    width: 46px;
    height: 46px;
  }
}

.svg-car {
  width: 71px;
  height: 48px;

  @media (max-width: $viewport-s) {
    width: 27px;
    height: 19px;
  }
}

.svg-cash {
  width: 49px;
  height: 43px;

  @media (max-width: $viewport-s) {
    width: 19px;
    height: 17px;
  }
}

.svg-card {
  width: 58px;
  height: 43px;

  @media (max-width: $viewport-s) {
    width: 22px;
    height: 17px;
  }
}

//footer__bottom

.footer__bottom {
  padding: 25px 0;
  background: #1B1A1A;

  @media (max-width: $viewport-s) {
    padding: 44px 0;
  }
}

.footer__contact {
  max-width: 1440px;
  padding: 0 6.94%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  gap: 33px;

  @media (max-width: $viewport-s) {
    grid-template-columns: auto;
    justify-content: initial;
    padding: 0 30px;
  }

  a {
    display: flex;
    align-items: center;
    font-size: Max(10px, Min(1.8vw, 25px));
    line-height: 1.16;

    @media (max-width: $viewport-s) {
      flex-direction: column;
    }
  }

  svg {
    flex-shrink: 0;
    margin-right: 21px;
    max-height: 44px;

    @media (max-width: $viewport-s) {
      margin-right: 0;
      margin-bottom: 20px;
      width: 33px;
    }
  }
}

.svg-phone {
  width: 37px;
}

.svg-mail {
  width: 44px;
}

.svg-mark {
  width: 34px;
}

.footer__logo {
  display: none;

  @media (max-width: $viewport-s) {
    display: flex;
    height: 67px;
    justify-content: center;
    align-items: center;
    background-color: $color-red;

    a {
      width: 25px;
      height: 47px;
      overflow: hidden;
    }
  }
}
