.info {
  display: flex;
  margin-top: 162px;
  align-items: flex-start;

  @media (max-width: $viewport-s) {
    margin-top: 12%;
    flex-direction: column;
    align-items: center;
  }
}

.info__wrap-img {
  width: 51.36%;
  flex-shrink: 0;
  margin-top: -95px;
  margin-right: -25.5%;
  max-height: 489px;
  border-radius: 25px;
  box-shadow: -10px 8px 35px rgba(0, 0, 0, .25);
  overflow: hidden;

  @media (max-width: $viewport-s) {
    width: 76.92%;
    margin: 0 0 -20px 0;
    border-radius: 10px;
  }

  img {
    background-color: $bg-img;
  }
}

.info__content {
  width: 74.12%;
  flex-shrink: 0;
  padding: 11.4% 4.82% 11.4% 32.08%;
  box-shadow: 10px 8px 35px rgba(0, 0, 0, .2);
  border-radius: 25px;
  background-image: url("../img/bg/main-decor-top.png"), url("../img/bg/main-decor-bottm.png");
  background-position: 80% 13%, 80% 87%;
  background-repeat: no-repeat, no-repeat;
  background-size: 43.9% auto, 43.9% auto;

  @media (max-width: $viewport-s) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: calc(50px + 7.7%) 7.69% calc(28px + 7.7%);
    border-radius: 10px;
    background-size: 55.77% auto, 55.77% auto;
    background-position: center top 37px, center bottom 13px;
  }

  p {
    font-size: Max(10px, Min(1.6vw, 25px));
    line-height: 1.26;

    & + p {
      margin-top: 15px;
    }
  }
}
