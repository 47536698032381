.certificate {
  max-width: 1010px;
  margin: 108px auto 0;
  padding: 0 50px;

  @media (max-width: $viewport-s) {
    margin-top: 14%;
  }

  b {
    display: block;
    margin-bottom: 14px;
    font-size: Max(10px, Min(2.1vw, 30px));
    font-weight: bold;
    line-height: 1.27;
    text-transform: uppercase;
    text-align: center;
    color: #EF1D00;
  }

  p {
    margin: -18px auto 0;
    max-width: 964px;
    font-weight: bold;
    font-size: Max(10px, Min(2.1vw, 30px));
    line-height: 1.27;
    text-align: center;
    color: #4D4D4D;

    @media (max-width: $viewport-s) {
      margin-top: 0;
    }
  }
}

.certificate__img-wrap {
  max-width: 575px;
  margin: auto;
  padding-top: 32px;
  background: url("../img/bg/main-decor-bottm.png") 0 0/100% auto no-repeat;

  @media (max-width: $viewport-s) {
    padding-top: 8px;
    background-size: 60% auto;
    background-position: 50% 0;
  }
}
