.header__wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $viewport-m) {
    background-color: $color-red;
  }
}

.header__logo-wrap {
  position: relative;
}

.header__logo {
  position: relative;
  width: 162px;
  height: 155px;
  padding: 0 15px 15px;
  background-color: $bg-logo;
  background-clip: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: $viewport-m) {
    background-color: initial;
    width: auto;
    height: 80px;
    padding: 0;
  }

  @media (max-width: $viewport-s) {
    height: 50px;
  }

  img {
    width: 47px;
    max-height: 89px;

    @media (max-width: $viewport-m) {
      width: auto;
      height: 50px;
    }

    @media (max-width: $viewport-s) {
      height: 35px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 30px;
    width: 132px;
    height: 160px;
    border: 5px solid $color-white;
    z-index: -1;

    @media (max-width: $viewport-m) {
      content: none;
    }
  }
}
