.nav {
  color: $color-white;
}

.nav__list {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $viewport-m) {
    position: absolute;
    width: 100%;
    max-height: 0;
    top: 100%;
    left: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: $color-red;
    opacity: .5;
    overflow: hidden;
    transform: translateY(-50px);
    transition-property: transform, max-height, opacity, visibility;
    transition-duration: $delay-default;
    z-index: -1;
  }

  li {
    &:not(:last-child) {
      margin-right: 43px;

      @media (max-width: $viewport-m) {
        margin-right: 0;
      }
    }
  }

  a {
    display: block;
    font-weight: bold;
    font-size: 25px;
    line-height: 32px;
    transition: color $delay-default, background-color $delay-default;
    text-align: center;

    &:hover,
    &:focus {
      color: $color-red;
    }

    @media (max-width: $viewport-m) {
      padding: 15px 0;

      &:hover,
      &:focus {
        color: inherit;
        background-color: #D81A00;
      }
    }
  }
}

.nav__toggle {
  position: relative;
  display: none;
  padding: 0;
  width: 22px;
  height: 20px;
  contain: strict;

  @media (max-width: $viewport-m) {
    display: block;
  }
}

.nav__burger {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -11px;
  width: 22px;
  height: 3px;
  border-radius: 5px;
  background-color: $color-white;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -7px;
    right: 0;
    width: 22px;
    height: 100%;
    border-radius: 5px;
    background-color: $color-white;
    transition: transform $delay-default;
  }

  &::after {
    top: 7px;
  }
}

// Меняет бергер на крестик
.nav--menu-active {
  .nav__burger {
    width: 0;
  }

  .nav__burger::before {
    transform: translateY(7px) rotate(-45deg);
  }

  .nav__burger::after {
    transform: translateY(-7px) rotate(45deg);
  }

  .nav__list {
    max-height: 500px;
    transform: translateY(0);
    opacity: 1;
  }
}
